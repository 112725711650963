import Link from "next/link";
import Image from "next/image";
import { Dispatch, SetStateAction } from "react";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils/cssUtils";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { signOut } from "next-auth/react";

const basePath = process.env.NEXT_PUBLIC_BASENAME ?? "";

interface NavMenuBottomProps {
  setNotesOpen: Dispatch<SetStateAction<boolean>>;
  role: string;
}

export const NavMenuBottom = ({ setNotesOpen, role }: NavMenuBottomProps) => {
  const pathname = usePathname();
  const activatedLinkClasses = "w-full rounded-none bg-[#6c717a]";

  return (
    <div className="mt-10 flex flex-col items-center justify-center gap-2.5">
      <SCNTooltip>
        <TooltipTrigger className="flex w-full items-center justify-center">
          <div
            className="rounded-md px-5 py-2 hover:cursor-pointer hover:bg-[#3e81ff]"
            onClick={() => setNotesOpen(true)}
          >
            <Image
              src={`${basePath}/New Task.svg`}
              alt="New Task"
              height={30}
              width={30}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">New Task</TooltipContent>
      </SCNTooltip>
      <SCNTooltip>
        <TooltipTrigger className="flex w-full items-center justify-center">
          <Link
            href={`/templaterManager`}
            className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/templaterManager" && activatedLinkClasses)}
          >
            <Image
              src={`${basePath}/TemplaterManager.svg`}
              alt="Templater Manager"
              height={30}
              width={30}
            />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right">Template Manager</TooltipContent>
      </SCNTooltip>
      <SCNTooltip>
        <TooltipTrigger className="flex w-full items-center justify-center">
          <Link
            href={`/AIVerification`}
            className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/AIVerification" && activatedLinkClasses)}
          >
            <Image
              src={`${basePath}/AI Review.svg`}
              alt="AI Verification"
              height={30}
              width={30}
            />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right">AI Verification</TooltipContent>
      </SCNTooltip>
      {role === "admin" && (
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/registerNewUserPage`}
              className={cn(
                `flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`,
                pathname === "/registerNewUserPage" && activatedLinkClasses
              )}
            >
              <Image
                src={`${basePath}/Register.svg`}
                alt="Register New User"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Register New User</TooltipContent>
        </SCNTooltip>
      )}
      <SCNTooltip>
        <TooltipTrigger className="flex w-full items-center justify-center">
          <Link
            href={`/userProfile`}
            className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/userProfile" && activatedLinkClasses)}
          >
            <Image
              src={`${basePath}/User Profile.svg`}
              alt="User Profile"
              height={30}
              width={30}
            />
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right">User Profile</TooltipContent>
      </SCNTooltip>
      {false && (
        <SCNTooltip>
          <TooltipTrigger className="flex w-full items-center justify-center">
            <Link
              href={`/settings`}
              className={cn(`flex justify-center rounded-md px-5 py-2 hover:!bg-[#3e81ff]`, pathname === "/settings" && activatedLinkClasses)}
            >
              <Image
                src={`${basePath}/Settings.svg`}
                alt="Settings"
                height={30}
                width={30}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Settings</TooltipContent>
        </SCNTooltip>
      )}
      <SCNTooltip>
        <TooltipTrigger className="flex w-full items-center justify-center">
          <div
            className="mb-3 rounded-md px-5 py-2 hover:cursor-pointer hover:bg-[#3e81ff]"
            onClick={() => {
              localStorage.removeItem("justLoggedIn");
              signOut({ callbackUrl: "/", redirect: true });
            }}
          >
            <Image
              src={`${basePath}/Logout.svg`}
              alt="Logout"
              height={30}
              width={30}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="right">Logout</TooltipContent>
      </SCNTooltip>
    </div>
  );
};
