import { EMRPhysician } from "@/lib/types/physician";
import customFetch from "./customFetch";
import { PatientFileType } from "@/lib/types/encounterNote";

const getUsers = async (): Promise<EMRPhysician[]> => {
  const { users } = await customFetch("/user");
  return users;
};

const getDoctors = async (): Promise<EMRPhysician[]> => {
  const { doctors } = await customFetch("/user/doctors");
  return doctors;
};

const getNewDoctorFilesList = async (doctorId: number): Promise<PatientFileType[]> => {
  const { files } = await customFetch(`/user/userID/${doctorId}/files`);
  return files;
};

const userAPI = {
  getUsers,
  getDoctors,
  getNewDoctorFilesList,
};

export default userAPI;
